
import { defineComponent } from "vue";
import * as GlobalStorage from "@/scripts/globalstorage";
import { parse } from "@/scripts/parser";
import Graph from "graphology";

export default defineComponent({
  name: "UploadPanel",
  methods: {
    parseDataset(event: { target: HTMLInputElement }): void {
      const file = (event.target.files as FileList)[0];
      const filename = file.name.replace(/\.[^/.]+$/, "");
      const graphID = GlobalStorage.createID();

      const onFinish = async (_: Graph) => {
        this.$emit("upload");
        // because Chrome/Safari weird, so we need to reset the <input>
        event.target.value = "";
      };

      parse(file, graphID, onFinish);
    },
  },
});
