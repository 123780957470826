
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    values: {
      type: Array,
      required: true,
    },
  },

  async mounted() {
    this.infotool = this.$refs["info-tool"] as HTMLElement;
  },

  data() {
    return {
      value_array: this.values,
      infotool: document.createElement('null'),
    };
  },

  methods: {

    setInfoTool(values: string[]) {
      this.infotool.innerHTML = "";
      values.forEach(element => {
        this.infotool.innerHTML += element;
      });
    },
  },

});
