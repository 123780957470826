
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    type: { required: true, type: String },
    path: { required: true, type: String },
  },
  methods: {
    onClick() {
      this.$emit("tile-click", this.type.replaceAll(" ", ""));
    },
  },
});
