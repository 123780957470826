
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    settingid: {
      type: String,
      required: true,
    },
    settinglabel: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    onChange(event: Event) {
      const target = event.target as HTMLInputElement;
      this.$emit("setting-changed", this.$props.settingid, target.checked);
    },
  },
});
