
import { defineComponent } from "vue";
import ArcDiagram from "@/components/vis-type/ArcDiagram.vue";
import SunburstDiagram from "@/components/vis-type/SunburstDiagram.vue";
import StatisticalDiagram from "@/components/vis-type/StatisticalDiagram.vue";
import AdjacencyMatrix from "@/components/vis-type/AdjacencyMatrix.vue";
import DiagramSettings from "@/components/DiagramSettings.vue";
import SelectSetting from "@/components/settings/SelectSetting.vue";
import * as GlobalStorage from "@/scripts/globalstorage";
import { debounce } from "lodash";

export default defineComponent({
  name: "DiagramPanel",
  components: {
    DiagramSettings,
    SelectSetting,
    ArcDiagram,
    SunburstDiagram,
    StatisticalDiagram,
    AdjacencyMatrix,
  },
  props: {
    diagram_id: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      diagram_name: "",
      componentType: "",
      showSettings: false,
      datasets: [] as string[],
    };
  },

  async created() {
    this.datasets = await GlobalStorage.getDatasets();
    const diagram = await GlobalStorage.getDiagram(this.diagram_id);
    if (!diagram) {
      console.warn("Non-existent diagram", this.diagram_id);
      return;
    }
    this.diagram_name = diagram.name;
    this.componentType = diagram.type;
    const diagram_panel = document.getElementsByClassName("diagram-panel");
    diagram_panel[0].addEventListener("resize", debounce((event) => {
      const diagrams = document.getElementsByClassName("diagram");
      Array.from(diagrams).forEach(element => {
        element.dispatchEvent(event);
      });
    }, 250));
  },

  methods: {
    async onSettingChanged(setting: string, value: any) {
      const diagram = await GlobalStorage.getDiagram(this.diagram_id);
      if (!diagram) {
        console.warn("Non-existent diagram:", this.diagram_id);
        return;
      }

      GlobalStorage.changeSetting(diagram, setting, value);
    },

    onNameChanged(name: string) {
      this.$emit("name-changed", name);
    },

    toggleSettings() {
      this.showSettings = !this.showSettings;
    },

    onSelectedNodeChange(datasetID: string, nodeID: string, append: boolean) {
      // just pass event to parent
      this.$emit("selected-node-change", datasetID, nodeID, append);
    },
  },
});
