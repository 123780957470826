
// create as follows:
// <diagram-settings diagramid="id-here" />

import { defineComponent } from "vue";
import * as GlobalStorage from "@/scripts/globalstorage";
import { getVisibleSettings, Setting } from "@/scripts/settingconfig";
import CheckboxSetting from "@/components/settings/CheckboxSetting.vue";
import NumberSetting from "@/components/settings/NumberSetting.vue";
import SelectSetting from "@/components/settings/SelectSetting.vue";
import TextSetting from "@/components/settings/TextSetting.vue";

export default defineComponent({
  components: {
    CheckboxSetting,
    NumberSetting,
    SelectSetting,
    TextSetting,
  },

  props: {
    diagramid: {
      type: String,
      required: true,
    },
    diagramname: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      diagram: null as any,
      type: "",
      settings: [] as Setting[],
    };
  },

  async mounted() {
    this.diagram = await GlobalStorage.getDiagram(this.diagramid);
    if (!this.diagram) {
      console.warn("Non-existent diagram:", this.diagramid);
      return;
    }
    const dataset = await GlobalStorage.getDataset(this.diagram.graphID);
    if (!dataset) {
      console.warn("Non-existent data set:", this.diagram.graphID);
      return;
    }

    this.settings = getVisibleSettings(this.diagram, dataset.graph);
    this.type = this.diagram.type;
  },

  methods: {
    onSettingChanged(id: string, value: any) {
      this.$emit("setting-changed", id, value);
    },

    onNameChanged(name: string) {
      if (!this.diagram) {
        console.warn("Non-existent diagram:", this.diagramid);
        return;
      }

      GlobalStorage.changeName(this.diagram, name);
      this.$emit("name-changed", name);
    },

    settingsName(type: string) {
      let diag_type = "";
      switch (type) {
        case "ArcDiagram":
          diag_type = "Arc Diagram"
          break;

        case "SunburstDiagram":
          diag_type = "Sunburst Diagram"
          break;

        case "StatisticalDiagram":
          diag_type = "Statistical Diagram"
          break;

        case "AdjacencyMatrix":
          diag_type = "Adjacency Matrix"
          break;

        default:
          diag_type = "Arc Diagram"
          break;
      }

      return diag_type;
    },
  },
});
