
import { defineComponent } from "vue";
import * as GlobalStorage from "@/scripts/globalstorage";

export default defineComponent({
  props: {
    id: { required: true, type: String },
    name: { required: true, type: String },
  },

  methods: {
    deleteDataset() {
      GlobalStorage.removeDataset(this.id);
      this.$emit("delete_data", this.id);
    },
  },
});
