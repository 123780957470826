
import { defineComponent } from "vue";
import * as GlobalStorage from "@/scripts/globalstorage";

export default defineComponent({
  props: {
    name: { required: true, type: String },
    id_name: { required: true, type: String },
    path: { required: true, type: String },
    datasetID: { required: true, type: String }
  },

  methods: {
    deleteDiagram() {
      GlobalStorage.removeDiagramByID(this.id_name);
      this.$emit("delete_diag", this.id_name);
    },

    openDiagram() {
      this.$emit("tile-click", this.id_name.replaceAll(" ", ""));
    }
  },
});
