
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    settingid: {
      type: String,
      required: true,
    },
    settinglabel: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      default: () => 0,
    },
    max: {
      type: Number,
      default: () => 10000000,
    },
    value: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      timeout: null as number | null,
    };
  },

  methods: {
    onChange(event: Event) {
      const target = this.$refs["number-input"] as HTMLInputElement;
      const number = parseFloat(target.value);
      if (!isNaN(number) && number >= this.min && number <= this.max) {
        this.$emit("setting-changed", this.$props.settingid, number);
      }
    },
  },
});
